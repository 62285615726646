<template>
  <div>
    <div id="output" ref="output"></div>
    <div id="print" ref="print">
      <v-container class="text-center pa-0" v-if="!empty(event)">
        <v-card :loading="loading" class="mx-auto" max-width="420">
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <v-img
            crossorigin="anonymous"
            height="250"
            :src="imUrl + event.logo_path"
            id="event_logo"
            ref="event_logo"
          ></v-img>

          <v-card-title>{{ event.name }}</v-card-title>

          <v-card-text>
            <!--<v-row align="center" class="mx-0">
            <v-rating
              :value="4.5"
              color="amber"
              dense
              half-increments
              readonly
              size="14"
            ></v-rating>
  
            <div class="grey--text ms-4">4.5 (413)</div>
          </v-row>-->

            <!--<div class="my-4 text-subtitle-1">{{ event.fare }}</div>-->

            <div>
              {{ event.intro_text }}
            </div>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <template v-if="!completed">
            <v-card-title class="text-center">
              <div style="width: 100%">
                {{ formatDate(event.from_date, "MMM DD, yyyy") }}
                {{ " to " }}
                {{ formatDate(event.to_date, "MMM DD, yyyy") }}
              </div>
            </v-card-title>
            <v-card-text>
              <div style="width: 100%">
                <v-chip color="green" text-color="white">
                  {{ event.start_time.substring(0, 5) }}
                  {{ " to " }}
                  {{ event.end_time.substring(0, 5) }}
                </v-chip>
              </div>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <div class="pt-3 pb-3">{{ event.custom_header }}</div>

            <v-form
              v-if="event.status_id != -1"
              ref="form"
              v-model="valid"
              lazy-validation
              class="mr-3 ml-3"
            >
              <v-radio-group
                v-model="visitor_types_id"
                :column="false"
                @change="visitor_type_selected"
              >
                <v-radio
                  v-for="v in visitor_types"
                  :key="v.id"
                  :label="v.name"
                  :value="v.id"
                ></v-radio>
              </v-radio-group>

              <v-text-field
                v-model="name"
                :rules="nameRules"
                label="Name"
                required
              ></v-text-field>

              <v-text-field
                v-model="email"
                label="E-mail"
                :rules="emailRules"
              ></v-text-field>

              <v-text-field
                v-model="mobile"
                :rules="mobileRules"
                label="Mobile Number"
                required
              ></v-text-field>

              <v-radio-group
                v-model="gender"
                :column="false"
                :rules="genderRules"
              >
                <v-radio
                  v-for="v in genders"
                  :key="v.id"
                  :label="v.name"
                  :value="v.id"
                ></v-radio>
              </v-radio-group>

              <v-row>
                <v-col cols="10" sm="10">
                  <v-slider
                    v-model="age"
                    :rules="ageRules"
                    color="orange"
                    label="Age"
                    min="1"
                    max="100"
                    thumb-label
                  ></v-slider>
                </v-col>
                <v-col cols="2" sm="2">
                  <div class="v-text-field__slot">
                    <input
                      disabled="disabled"
                      id="input-45"
                      type="text"
                      v-model="age"
                    />
                  </div>
                </v-col>
              </v-row>

              <v-text-field
                :rules="addressRules"
                v-model="address"
                label="Address"
              ></v-text-field>

              <v-text-field
                :rules="designationRules"
                v-model="designation"
                label="Designation"
              ></v-text-field>

              <v-text-field
                :rules="companyRules"
                v-model="company"
                label="Company"
              ></v-text-field>

              <div class="text-left">Remind me on the day of the Event</div>
              <v-checkbox
                v-model="notify_text"
                label="Via Text Message"
              ></v-checkbox>
              <v-checkbox v-model="notify_email" label="Via Email"></v-checkbox>

              <v-btn color="success" class="mr-4" @click="makeRSVP">
                Register
              </v-btn>
            </v-form>

            <v-card-title v-if="event.status_id == -1">
              Event Finished
            </v-card-title>
          </template>
          <template v-else>
            <v-card-subtitle class="text-center">
              <div>
                <img
                  width="150px"
                  crossorigin="anonymous"
                  class="el-upload-list__item-thumbnail"
                  :src="'data:image/png;base64,' + qr_code"
                  alt="QR Code"
                />
              </div>
              {{ event.booking_confirmed_message }}
              <div>
                <div class="field">
                  <p class="control">
                    <a
                      id="download_qr_code"
                      :href="'data:image/png;base64,' + qr_code"
                      download="cbimage.jpg"
                      class="button download-button"
                      style=""
                    >
                      <span>Save QR Code</span>
                    </a>
                  </p>
                </div>
              </div>
            </v-card-subtitle>
          </template>
          <v-divider class="mx-4"></v-divider>
          <div class="mt-3 mb-10 pt-3 pb-5">{{ event.custom_footer }}</div>
        </v-card>
        <v-overlay :absolute="false" :value="loading">
          <v-progress-circular
            :size="200"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
      </v-container>
    </div>
  </div>
</template>
<script>
import { imageUrl } from "../../utils/repo";
import html2canvas from "html2canvas";
export default {
  created() {
    this.getEventDetails();
  },
  data() {
    return {
      event: {
        name: "View Event Registration",
        fare: "FREE ENTRANCE",
        status_id: -1,
        intro_text: "",
        date: "",
        start_time: "",
        end_time: "",
        custom_footer: "",
        custom_header: "",
        booking_confirmed_message: "",
        logo_path: "",
      },
      genders: [
        { id: "Male", name: "Male" },
        { id: "Female", name: "Female" },
      ],
      qr_code: null,
      visitor_types: [],
      bookings_id: "",
      completed: false,
      loading: false,
      valid: false,
      notify_text: true,
      notify_email: false,
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      visitor_types_id: 1,
      address: null,
      addressRules: [(v) => !!v || "Address is required"],
      gender: null,
      genderRules: [(v) => !!v || "Gender is required"],
      age: null,
      ageRules: [
        (v) => !!v || "Age is required",
        (v) => v >= 16 || "You should be above 16 years",
      ],
      designation: "",
      designationRules: [(v) => !!v || "Designation is required"],
      company: "",
      companyRules: [],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      mobile: "",
      mobileRules: [
        (v) => !!v || "Mobile Number is required",
        (v) => (v > 7000000 && v < 9999999) || "Mobile Number is invalid",
      ],
      message: null,
      field_1: null,
      field_2: null,
      field_3: null,
      field_4: null,
      field_5: null,
    };
  },
  methods: {
    getEventDetails() {
      let f = {
        codes_id: this.$route.params.id,
        slug: this.$route.params.slug,
      };
      this.loading = true;
      this.api("rsvp", f)
        .then((response) => {
          this.visitor_types = response.data.visitor_types;
          this.visitor_types_id = response.data.visitor_types[0].id;
          this.visitor_type_selected();
          this.loading = false;
          this.event = response.data;
          this.event.fare = "FREE ENTRANCE";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    makeRSVP() {
      this.$refs.form.validate();
      if (!this.valid) return;
      if (this.empty(this.name) || this.empty(this.mobile)) return;
      let f = {
        events_id: this.event.id,
        event_codes_id: this.$route.params.id,
        event_fares_id: this.event.fares[0].id,
        event_dates_id: this.event.dates[0].id,
        name: this.name,
        email: this.email,
        mobile_no: this.mobile,
        company: this.company,
        notify_text: this.notify_text,
        notify_email: this.notify_email,
        message: this.message,
        visitor_types_id: this.visitor_types_id,
        designation: this.designation,
        address: this.address,
        age: this.age,
        gender: this.gender,
        field_1: this.field_1,
        field_2: this.field_2,
        field_3: this.field_3,
        field_4: this.field_4,
        field_5: this.field_5,
      };
      this.loading = true;
      this.api("make-rsvp", f)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.status == true) {
            this.event = response.data;
            this.qr_code = response.qr_code;
            this.bookings_id = response.bookings_id;
            this.completed = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    visitor_type_selected() {
      this.companyRules = [];
      this.designationRules = [];
      this.emailRules = [];
      this.addressRules = [];
      this.ageRules = [];
      this.genderRules = [];
      let v = this.getObjectFromArray(
        this.visitor_types,
        "id",
        this.visitor_types_id
      );
      if (!this.empty(v) && v.length > 0) {
        if (v[0].require_company == true) {
          this.companyRules = [(v) => !!v || "Companys is required"];
        }
        if (v[0].require_designation == true) {
          this.designationRules = [(v) => !!v || "Designation is required"];
        }
        if (v[0].require_email == true) {
          this.emailRules = [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
          ];
        }
        if (v[0].require_address == true) {
          this.addressRules = [(v) => !!v || "Address is required"];
        }
        if (v[0].require_age == true) {
          this.ageRules = [
            (v) => !!v || "Age is required",
            (v) => v >= 16 || "You should be above 16 years",
          ];
        }
        if (v[0].require_gender == true) {
          this.genderRules = [(v) => !!v || "Gender is required"];
        }
      }
    },
    async print() {
      document.getElementById("print_button").style.display = "none";
      let self = this;
      html2canvas(document.getElementById("print"), {
        allowTaint: true,
        useCORS: true,
      }).then(function (canvas) {
        canvas.id = "h2canvas";
        canvas.ref = "h2canvas";
        document.getElementById("output").appendChild(canvas);
        console.log(canvas.toDataURL);
        //self.saveAs(canvas.toDataURL(), "file-name.png");
        self.out(canvas);
      });
    },
    async out(canvas) {
      document.getElementById("print").style.display = "none";
      const printCanvas = canvas; // el.innerHTML; // await html2canvas(el, options);

      const link = document.createElement("a");
      link.setAttribute("download", "download.png");
      link.setAttribute(
        "href",
        printCanvas
          .toDataURL("image/jpg")
          .replace("image/jpg", "image/octet-stream")
      );
      link.click();

      console.log("done");
    },
  },
  name: "Public",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        console.log(to);
        document.title = "Event Booking Manager";
      },
    },
    event: function () {
      document.title = this.event.name;
    },
  },
  computed: {
    imUrl() {
      return `${imageUrl}`;
    },
  },
};
</script>
<style>
.theme--light.v-application {
  background: #aba9a9 !important;
}
.v-radio {
  margin-right: 20px;
}
</style>
